import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dashboard, Logout, Notifications, Person } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import Colors from "assets/style/Colors";
import { CircleLoading } from "components/loaders/Loaders";
import { useAuth } from "context/UseContext";
import { ErrorToaster } from "components/toaster/Toaster";
import Images from "assets/images/Images";

function DropDown({ anchorEl, openDropdown, handleClose }) {
  const { userLogout } = useAuth();
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiSvgIcon-root": {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={() => navigate("/")}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        Dashboard
      </MenuItem>
      <MenuItem onClick={() => navigate("/account-setting")}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => userLogout()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
}

function Header() {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const [notifyAnchor, setNotifyAnchor] = useState(null);
  const openNotificationList = Boolean(notifyAnchor);

  // *For Notification
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState();

  // *For Get Notifications
  const getNotifications = async () => {
    setLoading(true);
    try {
      setNotifications([]);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppBar
      elevation={0}
      position="relative"
      sx={{ backgroundColor: Colors.white }}
    >
      <Toolbar sx={{ px: { xl: 6, xs: 3 } }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton aria-label="notification" sx={{ mx: 1, color: Colors.secondary, ':hover': { backgroundColor: Colors.white } }} onClick={(e) => { setNotifyAnchor(e.currentTarget); getNotifications() }}>
            <Badge badgeContent={notificationCount} color="secondary" >
              <Notifications />
            </Badge>
          </IconButton> */}

          {/* ========== Notification Dropdown ========== */}

          {/* <Menu
            anchorEl={notifyAnchor}
            open={openNotificationList}
            onClose={() => {
              setNotifyAnchor(null);
              setNotificationCount(0);
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 0,
              sx: {
                width: "390px",
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                borderRadius: "12px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <Box sx={{ px: "16px", py: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
              {loading ? (
                <CircleLoading />
              ) : notifications?.length > 0 ? (
                notifications.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => setNotifyAnchor(null)}
                    sx={{
                      borderBottom: `1px solid ${Colors.smokeyGrey + "4D"}`,
                      bgcolor:
                        index >= notificationCount
                          ? "transparent"
                          : Colors.textPrimary + "40",
                      py: 2,
                      my: 0.3,
                      gap: "10px",
                      alignItems: "start",
                      ":hover": {
                        bgcolor:
                          index >= notificationCount
                            ? "transparent"
                            : Colors.textPrimary + "40",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={""}
                      sx={{
                        objectFit: "contain",
                        width: "50px",
                      }}
                    />
                    <Box sx={{ whiteSpace: "break-spaces" }}>
                      <Typography variant="body2">{item.title}</Typography>
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <Typography
                  variant="body1"
                  sx={{ py: 2, px: 1, textAlign: "center" }}
                >
                  Your have no notification.
                </Typography>
              )}
            </Box>
          </Menu> */}

          <Box
            sx={{ cursor: "pointer" }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Box
              component="img"
              src={Images.defaultAvatar}
              sx={{
                width: 30,
                height: 30,
                borderRadius: "50%",
              }}
            />
          </Box>
        </Box>
        <DropDown
          anchorEl={anchorEl}
          openDropdown={openDropdown}
          handleClose={() => setAnchorEl(null)}
        />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
