import { ReviewRoutes } from "./Reviews.Routes";
import { get } from "../index";

const ReviewServices = {
  getAllReviews: async () => {
    const data = await get(ReviewRoutes.getAllReviews);
    return data;
  },
};

export default ReviewServices;
