const Colors = {
    primary: '#012c58',
    primaryGradient: 'linear-gradient(to right, #294787, #213d7a, #19326e, #0f2961, #051f55)',
    secondary: '#0673de',
    secondaryGradient: 'linear-gradient(90deg,#11998e 0%,#38ef7d 100%)',
    tertiary: '#1b1b29',
    tertiaryGradient: 'linear-gradient(90deg,#ee0979 0%,#ff6a00 100%)',
    accepted: 'linear-gradient(90deg,#45b649 0%,#dce35b 100%)',
    textPrimary: '#001737',
    textSecondary: '#939393',
    charcoalBlack: '#414141',
    grey: '#7e7e7e',
    gunMetal: '#263238',
    cyanBlue: '#F6F9FC',
    white: '#ffffff',
    black: '#222222',
    danger: '#FF2121',
    success: '#06dc6a',


    titanWhite: '#dfdfdf',
    desertStorm: '#f5f5f5',
    stromWhite: '#f8f8f8',
    whiteShade: "#f3f3f3",
    ghostWhite: '#F5F8FC',
    snowDrift: '#F9F9F9',
    flashWhite: '#EFF0F6',
    mercury: '#d6d9d8',
    smokyBlack: '#0707074d',
    textLight: '#484D50',
    yellowishOrange: '#FF8B004D',
    ebonyClay: '#dfedf1',
    cloud: '#C4C4C4',
};

export default Colors;