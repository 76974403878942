import React, { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  CardMedia,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Navigations from "Navigation";
import Colors from "assets/style/Colors";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import Images from "assets/images/Images";

function Nav() {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const { pathname } = useLocation();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  return (
    <Box
      sx={{
        bgcolor: Colors.primary,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
          cursor: "pointer",
          width: "100%",
          height: "150px",
        }}
        onClick={() => navigate("/")}
      >
        <CardMedia
          component={"img"}
          src={Images.logo}
          sx={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </Box>
      <Box>
        <List sx={{ pt: 5 }}>
          {Navigations.map((item, index) => {
            const isSelected = item.path === pathname ? true : false;
            return (
              <Fragment key={index}>
                <ListItemButton
                  key={index}
                  component={item.path ? Link : "div"}
                  to={item.path ?? ""}
                  onClick={() =>
                    item?.children?.length > 0 && handleCollapse(item.label)
                  }
                  sx={{
                    mt: 0,
                    // background: isSelected ? Colors.charcoalBlack : "",
                    ".MuiSvgIcon-root": {
                      color: isSelected ? Colors.white : Colors.textSecondary,
                    },
                    "&:hover": {
                      // background: Colors.charcoalBlack,
                      ".MuiSvgIcon-root": {
                        color: Colors.white,
                      },
                      ".MuiTypography-root": {
                        color: Colors.white,
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          color: isSelected
                            ? Colors.white
                            : Colors.textSecondary,
                          fontWeight: isSelected ? "bold" : "500",
                        }}
                      >
                        {item.label}
                      </Typography>
                    }
                  />
                  {item?.children &&
                    (expand.indexOf(item.label) !== -1 ? (
                      <ExpandLess sx={{ color: Colors.textSecondary }} />
                    ) : (
                      <ExpandMore sx={{ color: Colors.textSecondary }} />
                    ))}
                </ListItemButton>
                {item?.children && (
                  <Collapse
                    in={expand.indexOf(item.label) !== -1}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.children.map((child, i) => (
                        <ListItemButton
                          key={i}
                          component={child.path ? Link : "div"}
                          to={child.path}
                          sx={{
                            mt: 0.5,
                            pl: "50px",
                            // background: child.path === pathname ? Colors.charcoalBlack : "",
                            ".MuiSvgIcon-root": {
                              color:
                                child.path === pathname
                                  ? Colors.white
                                  : Colors.textSecondary,
                            },
                            "&:hover": {
                              // background: Colors.charcoalBlack,
                              ".MuiSvgIcon-root": {
                                color: Colors.white,
                              },
                              ".MuiTypography-root": {
                                color: Colors.white,
                              },
                            },
                          }}
                        >
                          {/* <ListItemIcon sx={{ minWidth: 30, '.MuiSvgIcon-root': { fontSize: "16px" } }}>
                            {child.icon}
                          </ListItemIcon> */}
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight:
                                    child.path === pathname ? "bold" : "normal",
                                  color:
                                    child.path === pathname
                                      ? Colors.white
                                      : Colors.textSecondary,
                                }}
                              >
                                {child.label}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}

function Sidebar() {
  return (
    <Fragment>
      {/* <Drawer
        anchor="left"
        open={false}
        sx={{ display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { width: 250 } }}
      >
        <Nav />
      </Drawer> */}

      <Box sx={{ display: { xs: "none", md: "block" }, width: "20%" }}>
        <Nav />
      </Box>
    </Fragment>
  );
}

export default Sidebar;
