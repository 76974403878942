import { useState, useEffect, Fragment } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Pagination,
  Typography,
} from "@mui/material";
import Colors from "assets/style/Colors";
import React from "react";
import ReviewServices from "apis/reviews/ReviewsServices";
import { CircleLoading } from "components/loaders/Loaders";
import { baseUrl } from "config/axios";
import moment from "moment";
import { Star, StarBorder } from "@mui/icons-material";

const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
  "&:nth-of-type(odd)": {
    backgroundColor: Colors.desertStorm,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

const StarRating = ({ rating }) => {
  const filledStars = Array.from({ length: rating }, (_, index) => (
    <Star key={index} color="primary" />
  ));

  const emptyStars = Array.from({ length: 5 - rating }, (_, index) => (
    <StarBorder key={index} color="primary" />
  ));

  return (
    <div>
      {filledStars}
      {emptyStars}
    </div>
  );
};

function Feedback() {
  const [row, setRow] = useState([]);
  const [loader, setLoader] = useState(false);

  //For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const tableHead = ["Full name", "Phone number", "Star", "Comments", "Date"];

  const getReviews = async () => {
    try {
      setLoader(true);
      const { data, responseCode } = await ReviewServices.getAllReviews();
      if (responseCode === 200) {
        setRow(data?.review);
        console.log(data?.review);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
          {" "}
          Feedbacks
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: 2,
          width: "100%",
          minWidth: "fit-content",
          maxHeight: { xs: "auto", sm: "auto", md: "auto" }, //"calc(100vh - 300px)"
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: Colors.lightGray }}>
              {tableHead.map((cell, index) => (
                <Cell key={index}>{cell}</Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader ? (
              <Fragment>
                {row?.length > 0 ? (
                  <Fragment>
                    {row?.map((row, index) => (
                      <Row
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Cell>{row?.user_id?.name}</Cell>
                        <Cell>{row?.user_id?.phone}</Cell>
                        <Cell>
                          <StarRating rating={row?.rating} />
                        </Cell>
                        <Cell>{row.review}</Cell>
                        <Cell>
                          {moment(row?.createdAt).format("DD/MM/yyyy")}
                        </Cell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={5} align="center" sx={{ fontWeight: 600 }}>
                      c
                    </Cell>
                  </Row>
                )}
              </Fragment>
            ) : (
              <Row>
                <Cell colSpan={5} align="center" sx={{ fontWeight: 600 }}>
                  <CircleLoading />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 3,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / pageLimit)}
          showFirstButton
          showLastButton
        // onChange={(e, page) => getShortCode(undefined, page, "")}
        />
      </Box>
    </Box>
  );
}

export default Feedback;
