import { UserRoutes } from "./Users.Routes";
import { get } from "../index";

const UserService = {
  getAllUsers: async () => {
    const data = await get(UserRoutes.getAllUsers);
    return data;
  },
};

export default UserService;
