import { useTheme } from "@emotion/react";
import {
  AddBusinessTwoTone,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import ShortServices from "apis/shortCode/ShortServices";
import { DeleteIcon, EditIcon } from "assets/images/Images";
import Colors from "assets/style/Colors";
import { PrimaryButton } from "components/buttons/Buttons";
import SimpleDialog from "components/dialog/SimpleDialog";
import InputField from "components/fields/InputField";
import SearchField from "components/fields/SearchField";
import SelectField from "components/fields/SelectField";
import { CircleLoading } from "components/loaders/Loaders";
import { ErrorToaster, SuccessToaster } from "components/toaster/Toaster";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
  "&:nth-of-type(odd)": {
    backgroundColor: Colors.desertStorm,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

function ShortCodes() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const {
    setValue: setValue2,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [short, setShort] = useState([]);
  const [category, setCategory] = useState([]);
  const [shortId, setShortId] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editShort, setEditShort] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  console.log("🚀 ~ file: index.js:93 ~ ShortCodes ~ searchKey:", searchKey);

  const categoryType = [
    { id: "field", name: "field" },
    { id: "block", name: "block" },
  ];

  //*addShortCode
  const addShortCode = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        name: formData.Name,
        code: formData.Code,
        placeholder: formData.PlaceHolder,
        category: formData.Category,
        type: formData.Type,
      };
      const { message } = await ShortServices.create(obj);
      SuccessToaster(message);
      reset();
      getShortCode();
      setOpen(false);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //*Get ShortCode
  const getShortCode = async (search, page, limit) => {
    console.log("search=>", search);
    setLoader(true);
    try {
      // const Search = search === undefined ? searchKey : search;
      const Search = search === undefined ? searchKey : search;
      console.log("🚀 ~ file: index.js:129 ~ getShortCode ~ Search:", Search);
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      setSearchKey(Search);
      setCurrentPage(Page);
      setPageLimit(Limit);
      let obj = {
        page: Page,
        limit: Limit,
        search: Search,
      };
      const { data } = await ShortServices.getShortCode(obj);
      console.log("🚀 ~ file: index.js:142 ~ getShortCode ~ data:", data);
      setShort(data.shortCodes);
      setTotalCount(data?.totalCount);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  //*Get Category
  const getCategory = async () => {
    setLoader(true);
    try {
      const { data } = await ShortServices.getCategory();
      const arr = [];
      data?.categories?.forEach((element) => {
        let obj = { id: element, name: element };
        arr.push(obj);
      });
      setCategory(arr);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //*Delete Category
  const deleteShortCode = async () => {
    setLoading(true);
    try {
      const { message } = await ShortServices.deleteShortCode(shortId);
      SuccessToaster(message);
      setShortId();
      setDeleteDialog(false);
      getShortCode();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //*Update ShortCode
  const updateShortCode = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        name: formData.Name,
      };
      const { message } = await ShortServices.updatedShortCode(obj, shortId);
      SuccessToaster(message);
      getShortCode();
      reset2();
      setEditShort(false);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getShortCode();
    getCategory();
  }, []);

  return (
    <Box>
      {/* ========== add Dialog ========== */}
      <SimpleDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add new Short Codes"
      >
        <Box component={"form"} onSubmit={handleSubmit(addShortCode)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InputField
                label="Name"
                type="text"
                error={errors?.Name?.message}
                register={register("Name", {
                  required: "Please enter name.",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <InputField
                label="Code"
                type="text"
                error={errors?.Code?.message}
                register={register("Code", {
                  required: "Please enter code.",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <InputField
                label="Placeholder"
                type="text"
                error={errors?.PlaceHolder?.message}
                register={register("PlaceHolder", {
                  required: "Please enter Placeholder.",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <SelectField
                label="Category"
                type="text"
                selected={selectedCategory}
                options={category}
                onChange={(e) => setSelectedCategory(e.target.value)}
                error={errors?.Category?.message}
                register={register(`Category`, {
                  required: "Please enter category.",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <SelectField
                label="Type"
                type="text"
                selected={selectedType}
                options={categoryType}
                onChange={(e) => setSelectedType(e.target.value)}
                error={errors?.Type?.message}
                register={register("Type", {
                  required: "Please enter type.",
                })}
              />
            </Grid>
          </Grid>
          <PrimaryButton
            title="Save"
            type="submit"
            loading={loading}
            btnColor={Colors.secondary}
          />
        </Box>
      </SimpleDialog>

      {/* ========== Edit Dialog ========== */}
      <SimpleDialog
        open={editShort}
        onClose={() => setEditShort(false)}
        title="Add new ShortCode"
      >
        <Box component={"form"} onSubmit={handleSubmit2(updateShortCode)}>
          <InputField
            label="Name"
            type="text"
            error={errors2?.Name?.message}
            register={register2("Name", {
              required: "Please enter name.",
            })}
          />
          <PrimaryButton
            title="Save"
            type="submit"
            loading={loading}
            btnColor={Colors.secondary}
          />
        </Box>
      </SimpleDialog>

      {/* ========== Delete Dialog ========== */}
      <SimpleDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        title={"Are you sure to remove this?"}
        action={deleteShortCode}
        loading={loading}
      >
        <Stack
          spacing={2}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "16px",
          }}
        >
          <PrimaryButton
            title="Yes"
            loading={loading}
            btnColor={Colors.secondary}
            onClick={() => deleteShortCode()}
          />
          <PrimaryButton
            title="No"
            onClick={() => setDeleteDialog(false)}
            btnColor={Colors.black}
          />
        </Stack>
      </SimpleDialog>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
          {" "}
          ShortCode List
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 2,
        }}
      >
        <Grid item md={3}>
          <Box sx={{ width: "85%" }}>
            <SearchField searchKey={(key) => getShortCode(key, 1, "")} />
          </Box>
        </Grid>
        <Grid item md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <PrimaryButton
            title="Add New Short  Codes"
            startIcon={<AddBusinessTwoTone />}
            btnColor={Colors.secondary}
            onClick={() => setOpen(true)}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: 2,
          maxHeight: { xs: "auto", sm: "auto", md: "calc(100vh - 300px)" },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ bgcolor: Colors.lightGray }}>
              <Cell>Name</Cell>
              <Cell>Code</Cell>
              <Cell>Placeholder</Cell>
              <Cell>Category</Cell>
              <Cell>Type</Cell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader ? (
              <Fragment>
                {category?.length > 0 ? (
                  <Fragment>
                    {short.map((row, index) => (
                      <Row
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Cell>{row.name}</Cell>
                        <Cell>{row.code}</Cell>
                        <Cell>{row.placeholder}</Cell>
                        <Cell>{row.category}</Cell>
                        <Cell>{row.type}</Cell>
                        <TableCell>
                          {/* <Tooltip title="Edit" >
                        <IconButton onClick={() => { setShortId(row._id); setDefaultData(row.name) }} >
                          <EditIcon sx={{ fontSize: "20px", color: Colors.secondary }} />
                        </IconButton>
                      </Tooltip> */}
                          {row.system_default === false && (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setShortId(row._id);
                                  setDeleteDialog(true);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    fontSize: "20px",
                                    color: Colors.danger,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={5} align="center" sx={{ fontWeight: 600 }}>
                      c
                    </Cell>
                  </Row>
                )}
              </Fragment>
            ) : (
              <Row>
                <Cell colSpan={5} align="center" sx={{ fontWeight: 600 }}>
                  <CircleLoading />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 3,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / pageLimit)}
          showFirstButton
          showLastButton
          onChange={(e, page) => getShortCode(undefined, page, "")}
        />
      </Box>
    </Box>
  );
}

export default ShortCodes;
