import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { PrimaryButton } from "components/buttons/Buttons";
import InputField from "components/fields/InputField";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SelectField from "components/fields/SelectField";
import Images, { DeleteIcon } from "assets/images/Images";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import CategoriesServices from "apis/category/CategoriesServices";
import { ErrorToaster, SuccessToaster } from "components/toaster/Toaster";
import { CategoryTwoTone } from "@mui/icons-material";
import ShortServices from "apis/shortCode/ShortServices";
import { CircleLoading } from "components/loaders/Loaders";
import NdaTemplatesServices from "apis/ndaTemplates/NdaTemplatesServices";
import { baseUrl } from "config/axios";
import AutoTextAreaFields from "components/fields/AutoTextAreaFields";
import SimpleDialog from "components/dialog/SimpleDialog";
import Colors from "assets/style/Colors";

function EditTemplate() {
  const { id } = useParams();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [detail, setDetail] = useState([]);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [terms, setTerms] = useState("");
  const [count, setCount] = useState(1);
  const [short, setShort] = useState([]);
  const [party1, setParty1] = useState([]);
  const [party2, setParty2] = useState([]);
  const [signatureFields, setSignatureFields] = useState([])
  const [quillBar, setQuillBar] = useState(false);
  const [selectedRef, setSelectedRef] = useState("");

  const purposeRef = useRef();
  const termRef = useRef();
  const handleButtonClick = (value) => {
    let quillInstance;
    if (selectedRef === "purpose") {
      quillInstance = purposeRef.current.getEditor();
    } else {
      quillInstance = termRef.current.getEditor();
    }
    const selection = quillInstance.getSelection();
    if (selection) {
      const cursorPosition = selection.index;
      const newText = value;
      quillInstance.clipboard.dangerouslyPasteHTML(
        cursorPosition,
        "&nbsp;" + newText + "&nbsp;"
      );
    }
  };
  const number = [
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
  ];
  const navigate = useNavigate();

  // *add NdaTemplates
  const create = async (formData) => {
    setLoading(true);
    try {
      if (party1.length === 0) {
        ErrorToaster("please select atleast 1 field in party1");
        return;
      }
      if (party2.length === 0) {
        ErrorToaster("please select atleast 1 field in party2");
        return;
      }
      if (purpose.length == 0) {
        ErrorToaster("please enter the purpose");
        return;
      }
      if (list.length == 0) {
        ErrorToaster("please enter the terms");
        return;
      }
      const headers = [
        formData?.header_values0,
        formData?.header_values1,
        formData?.header_values2,
      ].filter((e) => e !== undefined);
      let obj = {
        name: formData?.name,
        category: formData?.category,
        purpose: `<div>${purpose}</div>`,
        terms: list,
        signature_fields: [...party1, ...party2],
        header: count,
        footer: formData?.footer,
        header_values: headers,
      };
      const { data, message } = await NdaTemplatesServices.create(obj, id);
      SuccessToaster(message);
      reset();
      setParty1([]);
      setParty2([]);
      setCategory([]);
      setList([]);
      navigate("/preview", { state: data });
      setPurpose([]);
      window.open(`${baseUrl + data?.pdf}`, "_blank");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  const addTask = () => {
    if (terms.trim() !== "") {
      if (terms !== "<p><br></p>") {
        setTerms("");
        setList([...list, terms]);
      }
    }
  };

  const removeTask = (index) => {
    const updatedTasks = list.filter((_, i) => i !== index);
    setList(updatedTasks);
  };

  //*Get Detail
  const getDetail = async () => {
    setLoader(true);
    try {
      const { data } = await NdaTemplatesServices.getDetails(id);
      setDetail(data.template);
      getAllCategory(data?.template?.category);
      setLoader(true);
      setValue("name", data.template.name);
      setValue("footer", data.template.footer);
      // setParty1(data.template.signature_fields)
      const party1Arr = [];
      data?.template?.fields[0]?.party1.forEach((element) => {
        let party1Obj = {
          category: element?.category,
          placeholder: element?.placeholder,
          _id: element?._id,
          code: element?.code,
        };
        party1Arr.push(party1Obj);
      });
      setParty1(party1Arr);
      // setParty2(data.template.signature_fields)
      const party2Arr = [];
      data?.template?.fields[0]?.party2.forEach((element) => {
        let party2Obj = {
          category: element?.category,
          placeholder: element?.placeholder,
          _id: element?._id,
          code: element?.code,
        };
        party2Arr.push(party2Obj);
      });
      setParty2(party2Arr);
      setList(data?.template?.terms);
      setPurpose(data?.template?.purpose);
      setCount(data?.template?.header);
      setSignatureFields(data.template.signature_fields)
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const getAllCategory = async (id) => {
    try {
      const { data } = await CategoriesServices.getCategory();
      const subCat = data?.categories?.find((cat) => cat?._id == id);
      const mainCat = data?.categories?.find((cat) => cat?._id == id);
      setSelectedCategory(mainCat?.parent_id)
      getSubCategory(mainCat?.parent_id);
      setSelectedSubCategory(subCat?._id);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*Get Category
  const getCategory = async () => {
    setLoader(true);
    try {
      const { data } = await CategoriesServices.getParentCategory();
      const arr = [];
      data?.categories?.forEach((element) => {
        let obj = { id: element._id, name: element.name };
        arr.push(obj);
      });
      setCategory(arr);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //*Get Category
  const getSubCategory = async (id) => {
    setLoader(true);
    try {
      const { data } = await CategoriesServices.getSubCategory(id);
      const arr = [];
      data?.categories?.forEach((element) => {
        let obj = { id: element._id, name: element.name };
        arr.push(obj);
      });
      setSubCategory(arr);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //*Get ShortCode
  const getShortCode = async () => {
    setLoader(true);
    try {
      const { data } = await ShortServices.getShortCode();
      setShort(data.shortCodes);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  //*Update updatedTemplate
  const updatedTemplate = async (formData) => {
    console.log("🚀 ~ updatedTemplate ~ formData:", formData)
    setLoading(true);
    try {
      const header = 1;
      let obj = {
        name: formData?.name,
        category: selectedSubCategory == "" ? selectedCategory : selectedSubCategory,
        purpose: `<div>${purpose}</div>`,
        terms: list,
        signature_fields: [...party1, ...party2],
        header: count,
        footer: formData?.footer,
        header_values: header,
      };
      const { message } = await NdaTemplatesServices.updatedTemplate(obj, id);
      SuccessToaster(message);
      getDetail();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
    getShortCode();
    getDetail();
  }, [id]);

  function part1fields(data) {
    console.log("🚀 ~ part1fields ~ data:", data)
    const { category, placeholder, _id, code } = data;
    const shallowCopy = [...party1];
    const index = party1.findIndex((e) => e?._id === data?._id);
    if (index === -1) {
      shallowCopy.push({ category, placeholder, _id, code });
    } else {
      shallowCopy.splice(index, 1);
    }
    setParty1(shallowCopy);

    const updatedSignatureFields = [...signatureFields];
    const signatureIndex = updatedSignatureFields.indexOf(_id);
    if (signatureIndex === -1) {
      updatedSignatureFields.push(_id); // Add the _id if not present
    } else {
      updatedSignatureFields.splice(signatureIndex, 1); // Remove the _id if already present
    }
    setSignatureFields(updatedSignatureFields);
  }

  function part2fields(data) {
    const { category, placeholder, _id, code } = data;
    const shallowCopy = [...party2];
    const index = party2.findIndex((e) => e?._id === data?._id);
    if (index === -1) {
      shallowCopy.push({ category, placeholder, _id, code });
    } else {
      shallowCopy.splice(index, 1);
    }
    setParty2(shallowCopy);

    const updatedSignatureFields = [...signatureFields];
    const signatureIndex = updatedSignatureFields.indexOf(_id);
    if (signatureIndex === -1) {
      updatedSignatureFields.push(_id); // Add the _id if not present
    } else {
      updatedSignatureFields.splice(signatureIndex, 1); // Remove the _id if already present
    }
    setSignatureFields(updatedSignatureFields);
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "code-block",
  ];

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  const module = {
    toolbar: {
      container: "#toolbars",
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  return (
    <Fragment>
      <SimpleDialog
        open={quillBar}
        onClose={() => setQuillBar(false)}
        title="ShortCodes"
      >
        <Box>
          <Typography variant="subtitle1">Party 1</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", my: 1 }}>
            {short.map(
              (item, index) =>
                item.category === "party1" && (
                  <Box key={index} sx={{ margin: 1 }}>
                    <PrimaryButton
                      onClick={() => {
                        handleButtonClick(` {{c:${item.code},v:,l:10}} `);
                        setQuillBar(false);
                      }}
                      title={item.name}
                    />
                  </Box>
                )
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1">Party 2</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", my: 1 }}>
            {short.map(
              (item, index) =>
                item.category === "party2" && (
                  <Box key={index} sx={{ margin: 1 }}>
                    <PrimaryButton
                      onClick={() => {
                        handleButtonClick(` {{c:${item.code},v:,l:10}} `);
                        setQuillBar(false);
                      }}
                      title={item.name}
                    />
                  </Box>
                )
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1">Validity</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", my: 1 }}>
            {short.map(
              (item, index) =>
                item.category === "validity" && (
                  <Box key={index} sx={{ margin: 1 }}>
                    <PrimaryButton
                      onClick={() => {
                        handleButtonClick(` {{c:${item.code},v:,l:10}} `);
                        setQuillBar(false);
                      }}
                      title={item.name}
                    />
                  </Box>
                )
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1">Other</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", my: 1 }}>
            {short.map(
              (item, index) =>
                item.category === "other" && (
                  <Box key={index} sx={{ margin: 1 }}>
                    <PrimaryButton
                      onClick={() => {
                        handleButtonClick(` {{c:${item.code},v:,l:10}} `);
                        setQuillBar(false);
                      }}
                      title={item.name}
                    />
                  </Box>
                )
            )}
          </Box>
        </Box>
      </SimpleDialog>

      <Box component={"form"} onSubmit={handleSubmit(updatedTemplate)}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="subtitle1">Update Details</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <PrimaryButton
            title="Save"
            loading={loading}
            type="submit"
            btnColor={Colors.secondary}
          />
        </Box>
        <Grid container spacing={2} sx={{ pt: 3 }}>
          <Grid item md={6} sx={{ px: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Box
                  component="img"
                  src={Images.line}
                  sx={{ color: Colors.secondary, mr: 1 }}
                />
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  sx={{ color: Colors.secondary }}
                >
                  Template Name
                </Typography>
              </Box>
              <EditNoteIcon sx={{ color: Colors.secondary }} />
            </Box>
            <Box sx={{ ml: 1.5 }}>
              <InputField
                size={"small"}
                placeholder={"Name"}
                error={errors.name?.message}
                register={register("name", {
                  required: "Please enter the name.",
                })}
              />
            </Box>
          </Grid>
          <Grid item md={6} sx={{ px: 5 }}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Box
                      component="img"
                      src={Images.line}
                      sx={{ color: Colors.secondary, mr: 1 }}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={{ color: Colors.secondary }}
                    >
                      Categories
                    </Typography>
                  </Box>
                  {/* <EditNoteIcon sx={{ color: Colors.secondary }} /> */}
                </Box>
                <Box sx={{ ml: 1.5 }}>
                  <SelectField
                    size={"small"}
                    label="Category"
                    selected={selectedCategory}
                    options={category}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      getSubCategory(e.target.value);
                    }}
                    error={errors?.category?.message}
                    register={register("category", {
                      required: selectedCategory == "" ? "Please enter the Category." : false,
                    })}
                  />
                </Box>
              </Grid>
              {selectedCategory && (
                <Grid item md={6}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <Box
                        component="img"
                        src={Images.line}
                        sx={{ color: Colors.secondary, mr: 1 }}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{ color: Colors.secondary }}
                      >
                        Sub Categories
                      </Typography>
                    </Box>
                    {/* <EditNoteIcon sx={{ color: Colors.secondary }} /> */}
                  </Box>
                  <Box sx={{ ml: 1.5 }}>
                    <SelectField
                      size={"small"}
                      label="Sub Category"
                      selected={selectedSubCategory}
                      options={subCategory}
                      onChange={(e) => setSelectedSubCategory(e.target.value)}
                      error={errors?.subCategory?.message}
                      register={register("subCategory")}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={6} sx={{ px: 5 }}>
            <Box sx={{ my: "24px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Box
                    component="img"
                    src={Images.line}
                    sx={{ color: Colors.secondary, mr: 1 }}
                  />
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    sx={{ color: Colors.secondary }}
                  >
                    Footer
                  </Typography>
                </Box>
                <EditNoteIcon sx={{ color: Colors.secondary }} />
              </Box>
              <Box sx={{ ml: 1.5 }}>
                <InputField
                  size={"small"}
                  placeholder={"Footer"}
                  error={errors.footer?.message}
                  register={register("footer", {
                    required: "Please enter the Footer.",
                  })}
                />
              </Box>
            </Box>
            <Box>
              <Box sx={{ alignItems: "center", display: "flex", mb: 2 }}>
                <Box
                  component="img"
                  src={Images.line}
                  sx={{ color: Colors.secondary, mr: 1 }}
                />
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  sx={{ color: Colors.secondary }}
                >
                  Signature Fields
                </Typography>
              </Box>
              {loader ? (
                <CircleLoading />
              ) : (
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{ color: Colors.black }}
                      >
                        Party1
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {short.map((item, index) =>
                        item.category === "party1" && (
                          <Box key={index}>
                            <FormControlLabel
                              label={item.name}
                              control={
                                <Checkbox
                                  // checked={
                                  //   signatureFields.includes(item._id) ? true
                                  //     : party1.findIndex(
                                  //       (e) => e._id === item._id
                                  //     ) !== -1
                                  //       ? true
                                  //       : false
                                  // }
                                  checked={signatureFields.includes(item._id)}
                                  onClick={() => part1fields(item)}
                                />
                              }
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={{ color: Colors.black }}
                    >
                      Party2
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {short.map(
                      (item, index) =>
                        item.category === "party2" && (
                          <Box key={index}>
                            <FormControlLabel
                              label={item.name}
                              control={
                                <Checkbox
                                  // checked={
                                  //   party2?.findIndex(
                                  //     (e) => e._id === item._id
                                  //   ) !== -1
                                  //     ? false : signatureFields.includes(item._id)
                                  //       ? true
                                  //       : false
                                  // }
                                  checked={signatureFields.includes(item._id)}
                                  onClick={() => part2fields(item)}
                                />
                              }
                            />
                          </Box>
                        )
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={6} sx={{ px: 5 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}
            >
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Box
                  component="img"
                  src={Images.line}
                  sx={{ color: Colors.secondary, mr: 1 }}
                />
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  sx={{ color: Colors.secondary }}
                >
                  Purpose
                </Typography>
              </Box>
              <EditNoteIcon
                sx={{
                  color: Colors.secondary,
                  display: "flex",
                  flexDirection: "column-reverse",
                  alignItems: "end",
                }}
              />
            </Box>
            <Box sx={{ ml: 1.5 }}>
              <Box id="toolbars">
                <Box component={"span"} className="ql-formats">
                  <button className="ql-bold" />
                  <button className="ql-italic" />
                  <button className="ql-underline" />
                  <button className="ql-strike" />
                </Box>
                <Box component={"span"} className="ql-formats">
                  <button className="ql-blockquote" />
                  <button className="ql-code-block" />
                </Box>
                <Box component={"span"} className="ql-formats">
                  <button className="ql-header" value="1"></button>
                  <button className="ql-header" value="2"></button>
                </Box>
                <Box component={"span"} className="ql-formats">
                  <button className="ql-list" value="ordered" />
                  <button className="ql-list" value="bullet" />
                </Box>
                <Box component={"span"} className="ql-formats">
                  <Tooltip title="ShortCodes">
                    <button
                      className="ql-undo"
                      sx={{ color: Colors.textLight }}
                    >
                      <CategoryTwoTone
                        onClick={() => {
                          setQuillBar(true);
                          setSelectedRef("purpose");
                        }}
                      />
                    </button>
                  </Tooltip>
                </Box>
              </Box>
              <ReactQuill
                ref={purposeRef}
                theme="snow"
                placeholder={"NDA Purpose"}
                value={purpose}
                onChange={setPurpose}
                modules={module}
                formats={formats}
              />
            </Box>
            <Box sx={{ my: 6 }}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}
              >
                <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Box
                    component="img"
                    src={Images.line}
                    sx={{ color: Colors.secondary, mr: 1 }}
                  />
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    sx={{ color: Colors.secondary }}
                  >
                    Terms
                  </Typography>
                </Box>
                <EditNoteIcon sx={{ color: Colors.secondary }} />
              </Box>
              <Box sx={{ ml: 1.5, mb: 3 }}>
                <Box id="toolbar">
                  <Box component={"span"} className="ql-formats">
                    <button className="ql-bold" />
                    <button className="ql-italic" />
                    <button className="ql-underline" />
                    <button className="ql-strike" />
                  </Box>
                  <Box component={"span"} className="ql-formats">
                    <button className="ql-blockquote" />
                    <button className="ql-code-block" />
                  </Box>
                  <Box component={"span"} className="ql-formats">
                    <button className="ql-header" value="1"></button>
                    <button className="ql-header" value="2"></button>
                  </Box>
                  <Box component={"span"} className="ql-formats">
                    <button className="ql-list" value="ordered" />
                    <button className="ql-list" value="bullet" />
                  </Box>
                  <Box component={"span"} className="ql-formats">
                    <Tooltip title="ShortCodes">
                      <button
                        className="ql-undo"
                        sx={{ color: Colors.textLight }}
                      >
                        <CategoryTwoTone
                          onClick={() => {
                            setQuillBar(true);
                            setSelectedRef("term");
                          }}
                        />
                      </button>
                    </Tooltip>
                  </Box>
                </Box>
                <ReactQuill
                  ref={termRef}
                  theme="snow"
                  value={terms}
                  placeholder={"NDA Terms"}
                  onChange={setTerms}
                  modules={modules}
                  formats={formats}
                />
                <IconButton onClick={addTask} sx={{ float: "right" }}>
                  <AddCircleIcon
                    sx={{
                      color: Colors.secondary,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                </IconButton>
                <TableContainer sx={{ height: "100px", mt: 5 }}>
                  <Table>
                    <TableBody>
                      {list.map((list, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            my: 2,
                            backgroundColor: Colors.white,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TableCell
                            sx={{ pl: 2, py: "8px", wordBreak: "break-all" }}
                          >
                            <div dangerouslySetInnerHTML={{ __html: list }} />
                          </TableCell>
                          <TableCell sx={{ py: "8px" }}>
                            <IconButton
                              title="cancel"
                              onClick={() => removeTask(index)}
                              sx={{ ":hover": { background: "none" } }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}

export default EditTemplate;
