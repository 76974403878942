import {
  Computer,
  PermIdentityOutlined,
  ShortText,
  GroupOutlined,
  FeedbackOutlined,
} from "@mui/icons-material";
import BallotIcon from "@mui/icons-material/Ballot";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";

const Navigations = [
  {
    label: "Dashboard",
    icon: <Computer />,
    path: "/",
  },

  {
    label: "Templates",
    icon: <BallotIcon />,
    children: [
      {
        label: "List",
        path: "/templates-list",
      },

      {
        label: "Create",
        path: "/create-list",
      },
    ],
  },

  // {
  //   label: 'Categories',
  //   icon: <CategoryTwoToneIcon />,
  //   children: [
  //     {
  //       label: 'List',
  //       path: '/CategoriesList'
  //     },
  //   ]
  // },
  // {
  //   label: 'Account',
  //   icon: <PermIdentityOutlined />,
  //   children: [
  //     {
  //       label: 'AccountSetting',
  //       path: '/AccountSetting'
  //     },
  //   ]
  // },
  {
    label: "Categories",
    icon: <CategoryTwoToneIcon />,
    path: "/categories",
  },
  {
    label: "Short Codes",
    icon: <ShortText />,
    path: "/short-codes",
  },
  {
    label: "Account Setting",
    icon: <PermIdentityOutlined />,
    path: "/account-setting",
  },
  {
    label: "Users List",
    icon: <GroupOutlined />,
    path: "/userslist",
  },
  {
    label: "Feedbacks",
    icon: <FeedbackOutlined />,
    path: "/feedbacks",
  },
  // {
  //   label: 'Product Management',
  //   icon: <Storefront />,
  //   children: [
  //     {
  //       label: 'Tags',
  //       icon: <LocalOffer />,
  //       path: '/product-management/tags'
  //     },
  //   ]
  // },
];

export default Navigations;
