import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  Container,
  InputLabel,
} from "@mui/material";
import ProfileServices from "apis/profile/ProfileServices";
import Images, { EditIcon } from "assets/images/Images";
import { PrimaryButton } from "components/buttons/Buttons";
import InputField from "components/fields/InputField";
import { CircleLoading } from "components/loaders/Loaders";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { emailRegex } from "utils";
import "react-phone-input-2/lib/material.css";
import { baseUrl } from "config/axios";
import SimpleDialog from "components/dialog/SimpleDialog";
import { ErrorToaster, SuccessToaster } from "components/toaster/Toaster";
import Colors from "assets/style/Colors";

function AccountSetting() {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    watch,
    reset: reset2,
  } = useForm();
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [inputError, setInputError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profile, setProfile] = useState("");
  const [editAccountSetting, setEditAccountSetting] = useState(false);

  const setDefaultData = () => {
    setValue("Name", profile?.name);
    setValue("telephone", profile?.phone);
    setValue("email", profile?.email);
    setEditAccountSetting(true);
  };

  const password = useRef({});
  password.current = watch("password");

  const handleImageClick = () => {
    inputRef.current.click();
  };
  const handleImageChange = (event) => {
    const data = new FormData();
    data.append("image", event.target.files[0]);
    updatePicture(data);
  };

  //*updatePicture
  const updatePicture = async (file) => {
    try {
      const { data } = await ProfileServices.updatePicture(file);
      setImage(data?.uploadedDocs[0]?.path);
    } catch (error) {}
  };

  //*Get Profile
  const getProfile = async () => {
    setLoader(true);
    try {
      const { data } = await ProfileServices.getMyProfile();
      setProfile(data.user);
    } catch (error) {
      setInputError(true);
    } finally {
      setLoader(false);
    }
  };

  //*Update Profile
  const updateProfile = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        name: formData.Name,
        email: formData.email,
        phone: formData.telephone,
      };
      const { message } = await ProfileServices.updateProfile(obj);
      SuccessToaster(message);
      getProfile();
      reset();
      setEditAccountSetting(false);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //*Update Password
  const updatePassword = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        old_password: formData.OldPassword,
        password: formData.password,
      };
      const { message } = obj;
      SuccessToaster(message);
      reset2();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Fragment>
      {/* ========== Edit Dialog ========== */}
      <SimpleDialog
        open={editAccountSetting}
        onClose={() => setEditAccountSetting(false)}
        title="Edit Account Setting"
      >
        <Box component={"form"} onSubmit={handleSubmit(updateProfile)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputField
                label="Name"
                placeholder={"Enter  Name"}
                type="text"
                error={errors.Name?.message}
                register={register("Name", {
                  required: "Please enter the name.",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <InputField
                label={"Email"}
                type={"email"}
                placeholder={"Enter email address"}
                error={errors?.email?.message || inputError}
                register={register("email", {
                  required: "Please enter the email.",
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
            </Grid>
            <Grid item md={12}>
              <InputField
                label="Phone"
                placeholder={"Enter phone number"}
                type="number"
                error={errors.telephone?.message}
                register={register("telephone", {
                  required: "Please enter phone number.",
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                title="Save"
                loading={loading}
                type="submit"
                btnColor={Colors.secondary}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>

      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        Account Setting
      </Typography>
      {loader ? (
        <CircleLoading />
      ) : (
        <Box>
          <Grid
            container
            spacing={1}
            justifyContent={"space-between"}
            sx={{ m: 0 }}
          >
            <Grid item md={4}>
              <Box
                sx={{
                  backgroundColor: Colors.white,
                  padding: "38px 15px",
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 8px",
                }}
              >
                <Box sx={{ textAlign: "right", mb: 2 }}>
                  <IconButton onClick={() => setDefaultData()}>
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Box>
                    <Box
                      component="img"
                      src={Images.defaultAvatar}
                      sx={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                        // border: `2px solid ${Colors.secondary}`,
                      }}
                    />
                    <Typography
                      component="input"
                      type="file"
                      ref={inputRef}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </Box>
                  {/* <Box sx={{ mb: 2 }}>
                    <PrimaryButton
                      title="Upload Image"
                      onClick={handleImageClick}
                      btnColor={Colors.secondary}
                    />
                  </Box> */}
                </Box>
                <Grid container>
                  <Grid item md={12}>
                    <Box sx={{ display: "flex", gap: "5px", py: "15px" }}>
                      <Typography variant="subtitle3">Name :</Typography>
                      <Typography variant="body3">{profile?.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      className="text-truncate"
                      sx={{ display: "flex", gap: "5px", py: "15px" }}
                    >
                      <Typography component="span" variant="subtitle3">
                        Email :&nbsp;
                      </Typography>
                      <Typography component="span" variant="body3">
                        {profile?.email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        py: "15px",
                      }}
                    >
                      <Typography variant="subtitle3">Phone :</Typography>
                      <Typography variant="body3">{profile?.phone}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box
                sx={{
                  backgroundColor: Colors.white,
                  padding: "52px 32px",
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 8px",
                }}
              >
                <Typography variant="h6">Change Password</Typography>
                <Box
                  component={"form"}
                  onSubmit={handleSubmit2(updatePassword)}
                >
                  <Grid container spacing={2} sx={{ py: 2 }}>
                    <Grid item md={6}>
                      <InputLabel sx={{ color: Colors.black }}>
                        Old Password
                      </InputLabel>
                      <InputField
                        type={oldPassword ? "text" : "password"}
                        placeholder={"Old password."}
                        error={errors2.OldPassword?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setOldPassword(!oldPassword)}
                                edge="end"
                              >
                                {oldPassword ? (
                                  <Visibility color="secondary" />
                                ) : (
                                  <VisibilityOff color="secondary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register2("OldPassword", {
                          required: "Please enter the old password.",
                        })}
                      />
                    </Grid>
                    <Grid item md={6}></Grid>
                    <Grid item md={6}>
                      <InputLabel sx={{ color: Colors.black }}>
                        New Password
                      </InputLabel>
                      <InputField
                        type={showPassword ? "text" : "password"}
                        placeholder={"New password."}
                        error={errors2.password?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility color="secondary" />
                                ) : (
                                  <VisibilityOff color="secondary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register2("password", {
                          required: "Please enter the password.",
                          minLength: {
                            value: 6,
                            message: "password  must have atleast 6 character",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <InputLabel sx={{ color: Colors.black }}>
                        Confirm Password
                      </InputLabel>
                      <InputField
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder={"Confirm password."}
                        error={errors2.ConfirmPassword?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Visibility color="secondary" />
                                ) : (
                                  <VisibilityOff color="secondary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        register={register2("ConfirmPassword", {
                          required: "Please enter the Confirm Password.",
                          validate: (value) =>
                            value === password.current ||
                            "Confirm password does not match",
                          minLength: {
                            value: 6,
                            message: "password  must have atleast 6 character",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ my: 1 }}>
                      <PrimaryButton
                        title="Save"
                        loading={loading}
                        type="submit"
                        btnColor={Colors.secondary}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}

export default AccountSetting;
