import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Button, CardMedia, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { PrimaryButton, TertiaryButton } from 'components/buttons/Buttons'
import Colors from 'assets/style/Colors'
import { baseUrl } from 'config/axios'
import { Link, useNavigate } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import NdaTemplatesServices from 'apis/ndaTemplates/NdaTemplatesServices'

function PrimaryCard({ list, onClick }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid container sx={{ py: 2, px: 0, backgroundColor: Colors.white, borderRadius: '12px', height: "100%" }}>
        <Grid item md={3} >
          <CardMedia image={baseUrl + list.screenshot} sx={{
            height: '122px',
          }} />
        </Grid>
        <Grid item md={9} sx={{ px: '8px' }}>
          <Box sx={{ position: "relative", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Typography variant="subtitle1" sx={{
              mb: 1,
              wordBreak: 'break-all'
            }}>
              {list.name}
            </Typography>
            <Tooltip title={list.description}>
              <Typography variant='body2' sx={{
                mb: 2,
                height: '68.63px',
                wordBreak: 'break-all',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical'
              }}>
                {list.description}
              </Typography>
            </Tooltip>
            <Stack spacing={1} direction="row" justifyContent={"flex-end"} >
              <TertiaryButton
                title="preview"
                btnColor='1px solid #012c58'
                onClick={() => {
                  navigate("/preview", { state: list })
                }}
              />
              <Link to={`/edit-template/${list._id}`} >
                <PrimaryButton
                  title="edit"
                  btnColor={Colors.primary}
                />
              </Link>
            </Stack>

            <Box sx={{ position: "absolute", top: "-10px", left: "87%" }}>
              <IconButton onClick={onClick}>
                <Delete sx={{ color: "red" }}></Delete>
              </IconButton>
            </Box>
          </Box>
          {/* <Stack spacing={2} direction="row">
            <Button variant="outlined" sx={{
              fontSize: '10px',
              borderColor: '1px solid #012c58'
            }}>
              preview
            </Button>
            <Button sx={{
              fontSize: '10px',
              color: Colors.white,
              backgroundColor: Colors.primary,
              ':hover': {
                color: Colors.white,
                backgroundColor: Colors.primary,
              }
            }}>edit this page</Button>
          </Stack> */}
        </Grid>

      </Grid>
    </Fragment >
  )
}

export default PrimaryCard