import { useState } from 'react';
import { InputBase } from '@mui/material';
import styled from '@emotion/styled';
import { SearchCloseIcon, SearchIcon } from 'assets/images/Images';
import Colors from 'assets/style/Colors';

// *For Styling
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 40,
  backgroundColor: Colors.desertStorm,
  border: '1px solid #2134304d',
  marginLeft: 0,
  // maxWidth: '250px',
  width: '100%',
  height: 35,
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  left: theme.direction === 'ltr' ? 0 : '',
  right: theme.direction === 'rtl' ? 0 : '',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: '11'
}));

const CloseIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: theme.direction === 'ltr' ? 0 : '',
  left: theme.direction === 'rtl' ? 0 : '',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: '11'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1),
    paddingRight: theme.direction !== 'ltr' ? theme.spacing(5) : theme.spacing(4),
    paddingLeft: theme.direction !== 'rtl' ? theme.spacing(5) : theme.spacing(4),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '22ch',
    },
  },
}));

function SearchField({ searchKey }) {

  const [value, setValue] = useState(undefined);

  return (
    <Search>
      <SearchIconWrapper onClick={() => searchKey(value)}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={'Search'}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => e.code === 'Enter' && searchKey(value)}
      />
      {value &&
        <CloseIconWrapper onClick={() => { setValue(''); searchKey('') }}>
          <SearchCloseIcon />
        </CloseIconWrapper>
      }
    </Search>
  );
}

export default SearchField