import React, { lazy } from "react";
import AccountSetting from "views/account";
import CategoriesList from "views/categories";
import ShortCodes from "views/short";
import TemplatesList from "views/templates";
import Create from "views/templates/CreateTemplate";
import EditTemplate from "views/templates/EditTemplate";
import Preview from "views/templates/Preview";
import UserList from "views/userslist";
import Feedback from "views/feedbacks";

const Dashboard = lazy(() => import("views/dashboard"));

const DashboardRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/templates-list",
    component: <TemplatesList />,
  },
  {
    path: "/create-list",
    component: <Create />,
  },
  {
    path: "/preview",
    component: <Preview />,
  },
  {
    path: "/categories",
    component: <CategoriesList />,
  },
  {
    path: "/account-setting",
    component: <AccountSetting />,
  },
  {
    path: "/short-codes",
    component: <ShortCodes />,
  },
  {
    path: "/edit-template/:id",
    component: <EditTemplate />,
  },
  {
    path: "/userslist",
    component: <UserList />,
  },
  {
    path: "/feedbacks",
    component: <Feedback />,
  },
];

export default DashboardRoutes;
