import React, { Fragment, useState } from "react";
import { Typography, Grid, Box, Divider, Dialog, DialogContent, Button, DialogTitle } from "@mui/material";
import SearchField from "components/fields/SearchField";
import { PrimaryButton } from "components/buttons/Buttons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PrimaryCard from "components/card/PrimaryCard";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import { Link } from "react-router-dom";
import NdaTemplatesServices from "apis/ndaTemplates/NdaTemplatesServices";
import { ErrorToaster, SuccessToaster } from "components/toaster/Toaster";
import { useEffect } from "react";
import { CircleLoading } from "components/loaders/Loaders";
import CategoriesServices from "apis/category/CategoriesServices";
import Colors from "assets/style/Colors";

function TemplatesList() {
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedParentCategory, setSelectedParentCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [catSearch, setCatSearch] = useState("");

  // const categories = [{ id: 1, name: 'Patnership and Collaborations' }, { id: 2, name: 'Select 2' }]

  //*Get Templates
  const getTemplates = async (search, page, limit, category) => {
    setLoading(true);
    try {
      const Search = search === undefined ? searchKey : search;
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const cat = category ? category : selectedCategory;
      setSearchKey(Search);
      setCurrentPage(Page);
      setPageLimit(Limit);
      let obj = {
        page: Page,
        limit: Limit,
        name: Search,
      };
      if (cat) {
        obj.category = cat === "all" ? "" : cat;
      }
      const { data } = await NdaTemplatesServices.getTemplate(obj);
      if (Page > 1) {
        setTemplate((prev) => [...prev, ...data.templates]);
      } else {
        setTemplate(data.templates);
      }
      setTotalCount(data?.totalCount);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //*Get Category
  const getCategory = async (search) => {
    try {
      const Search = search === undefined ? catSearch : search;
      setCatSearch(Search);
      let obj = {
        search: Search,
      };
      const { data } = await CategoriesServices.getCategory(obj);
      const filterParents = data.categories.filter((cat) => cat.parent_name == null);
      setAllCategories(data?.categories);
      setCategory(filterParents);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getSubCategory = (id) => {
    const filteredSubCategory = allCategories.filter((cat) => cat.parent_id == id)
    setSubCategory(filteredSubCategory);
  }

  const deleteTemplate = async () => {
    console.log(id);
    try {
      const result = await NdaTemplatesServices.deleteTemplate(id);
      console.log(result);
      SuccessToaster(result?.message)
    } catch (error) {
      console.log(error)
    } finally {
      setIsOpen(false);
      getTemplates("", currentPage, pageLimit, "");
    }
  }

  useEffect(() => {
    getTemplates();
    getCategory();
  }, []);

  return (
    <Fragment>
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <DialogTitle>Delete Template?</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => { deleteTemplate(); }}>Delete</Button>
        </DialogContent>
      </Dialog>
      <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
        <Typography variant="subtitle1">Templates</Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Grid container>
        <Grid item md={3}>
          <Box
            sx={{
              background: Colors.desertStorm,
              borderRadius: "15px",
              px: "18px",
              py: "20px",
            }}
          >
            <Typography variant="body1" sx={{ mb: "10px" }}>
              Filter by Category
            </Typography>
            <Box sx={{ pb: "20px", my: 1.3, width: "85%" }}>
              <SearchField searchKey={(key) => getCategory(key)} />
            </Box>
            <Box
              sx={{
                backgroundColor:
                  selectedCategory === "all" ? Colors.ebonyClay : "transparent",
                borderRadius: "13px",
                px: "13px",
                py: "4px",
                mb: "15px",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: Colors.ebonyClay,
                  overflow: "hidden",
                },
              }}
              onClick={() => {
                getTemplates(undefined, 1, "", "all");
                setSelectedCategory("all");
              }}
            >
              <Box sx={{ display: "flex" }}>
                <WorkspacesOutlinedIcon
                  sx={{ mr: "6px", fontSize: "20px", py: "2px" }}
                />
                <Box sx={{ py: "3px" }}>
                  <Typography variant="body2">All</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ mb: "10px" }}>Main Category</Typography>
              <Box sx={{ height: "125px", overflowY: "auto" }}>
                {category.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      px: "14px",
                      py: "10px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedParentCategory === item._id
                          ? Colors.ebonyClay
                          : "transparent",
                      borderRadius: "13px",
                      ":hover": {
                        backgroundColor: Colors.ebonyClay,
                        overflow: "hidden",
                      },
                    }}
                    onClick={() => {
                      // getTemplates(undefined, 1, "", item?._id);
                      setSelectedParentCategory(item?._id);
                      getSubCategory(item?._id)
                    }}
                  >
                    <Typography variant="body2">{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider />
            {subCategory.length > 0 && (
              <Box sx={{ mt: "10px" }}>
                <Typography sx={{ mb: "10px" }}>Sub Category</Typography>
                <Box sx={{ height: "200px", overflowY: "auto" }}>
                  {subCategory.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        px: "14px",
                        py: "10px",
                        cursor: "pointer",
                        backgroundColor:
                          selectedSubCategory === item._id
                            ? Colors.ebonyClay
                            : "transparent",
                        borderRadius: "13px",
                        ":hover": {
                          backgroundColor: Colors.ebonyClay,
                          overflow: "hidden",
                        },
                      }}
                      onClick={() => {
                        getTemplates(undefined, 1, "", item?._id);
                        setSelectedSubCategory(item?._id);
                      }}
                    >
                      <Typography variant="body2">{item.name}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Divider />
            {/* <Typography variant="body1" sx={{ my: '10px' }} >
              Sort By
            </Typography>
            <Box sx={{ display: 'flex', py: '10px', mx: '20px' }}>
              <WorkspacesOutlinedIcon sx={{ mr: '10px' }} />
              <Typography variant="body2" >Newest</Typography>
            </Box>
            <Box sx={{ display: 'flex', py: '10px', mx: '20px' }}>
              <WorkspacesOutlinedIcon sx={{ mr: '10px' }} />
              <Typography variant="body2" >Oldest</Typography>
            </Box> */}
          </Box>
        </Grid>
        <Grid item md={9} sx={{ padding: "0px" }}>
          <Box sx={{ width: "25%", mx: 3 }}>
            <SearchField searchKey={(key) => getTemplates(key, 1, "")} />
          </Box>
          <Grid container>
            {template ? (
              <Fragment>
                {template?.length > 0 ? (
                  <Fragment>
                    {template.map((list, index) => (
                      <Grid key={index} item md={6} sx={{ pl: "25px", py: 1.8 }}>
                        <PrimaryCard list={list} onClick={() => {
                          setIsOpen(true);
                          setId(list?._id);
                        }} />
                      </Grid>
                    ))}
                  </Fragment>
                ) : (
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">No data found</Typography>
                  </Box>
                )}
              </Fragment>
            ) : (
              <Box sx={{ width: 1, textAlign: "center" }}>
                <CircleLoading />
              </Box>
            )}
          </Grid>
          <Box sx={{ display: totalCount == template?.length ? "none" : "flex", justifyContent: "center" }}>
            {totalCount !== template?.length && (
              <PrimaryButton
                title={"Load More"}
                loading={loading}
                onClick={() => getTemplates(undefined, currentPage + 1, "", "")}
              />
            )}
          </Box>
        </Grid>
        {/* < Grid item md={5} sx={{ margin: "65px 0px" }}>
          {cardDetail.map((list, index) => (
            <Grid item md={12} >
              <NewCard list={list} />
            </Grid>
          ))}
        </Grid> */}
      </Grid>
    </Fragment>
  );
}

export default TemplatesList;
