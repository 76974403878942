import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

function SelectField(props) {
  const {
    label,
    selected,
    width,
    register,
    error,
    options,
    onChange,
    size,
    disabled,
  } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      size={size}
      sx={{ my: 1.3, textAlign: "left" }}
    >
      <InputLabel error={error && selected === "" && true}>{label}</InputLabel>
      <Select
        disabled={disabled}
        label={label}
        input={<OutlinedInput label={label} />}
        value={selected}
        error={error && selected === "" && true}
        {...register}
        onChange={(e) => onChange(e)}
        MenuProps={MenuProps}
      >
        {options?.map((item, index) => (
          <MenuItem key={index} value={item.id} className="text-truncate">
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error && selected === "" && (
        <Typography color="error" sx={{ fontSize: 12, textAlign: "left" }}>
          {error}
        </Typography>
      )}
    </FormControl>
  );
}

export default SelectField;
