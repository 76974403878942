import { deleted, get, patch, post } from "apis";
import ShortRoutes from "./Short.Routes";

const ShortServices = {
  create: async (obj) => {
    const data = await post(ShortRoutes.create, obj);
    return data;
  },
  getShortCode: async (param) => {
    const data = await get(ShortRoutes.getShortCode, param);
    return data;
  },
  getCategory: async (param) => {
    const data = await get(ShortRoutes.getCategory, param);
    return data;
  },

  deleteShortCode: async (id) => {
    const data = await deleted(ShortRoutes.deleteShortCode + id);
    return data;
  },
  updatedShortCode: async (obj, id) => {
    const data = await patch(ShortRoutes.updatedShortCode + `/${id}`, obj);
    return data;
  },
}

export default ShortServices