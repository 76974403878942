import React, { Fragment, Suspense } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { CircleLoading } from "components/loaders/Loaders";
import Colors from "assets/style/Colors";

function DashboardLayout() {
  return (
    <Box sx={{ display: "flex", minHeight: "calc(100vh - 0px)" }}>
      {/* ========== Sidebar ========== */}
      <Sidebar />

      <Box component="main" sx={{ width: "80%" }}>
        {/* ========== Header ========== */}
        <Header />
        <Box
          sx={{
            position: "relative",
            backgroundColor: Colors.snowDrift,
            width: "100%",
          }}
        >
          <Suspense fallback={<CircleLoading />}>
            <Box sx={{
              py: "14px",
              px: 2,
            }}>
              <Outlet />
            </Box>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
