import { useState, useEffect, Fragment } from "react";
import {
  Box,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Pagination,
  Typography,
} from "@mui/material";
import Colors from "assets/style/Colors";
import React from "react";
import UserService from "apis/users/UsersServices";
import { CircleLoading } from "components/loaders/Loaders";
import { baseUrl } from "config/axios";
import moment from "moment";
import Images from "assets/images/Images";

const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
  "&:nth-of-type(odd)": {
    backgroundColor: Colors.desertStorm,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: 700,
    border: 0,
    whiteSpace: "nowrap",
    backgroundColor: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
  },
}));

function UserList() {
  const [row, setRow] = useState([]);
  const [loader, setLoader] = useState(false);

  //For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const tableHead = [
    "Profile picture",
    "Full name",
    "Phone number",
    "Email address",
    "DOB",
    "Country",
    "Registration Date",
  ];
  const getUsers = async (search, page, limit) => {
    setLoader(true);
    try {
      const Search = search === undefined ? searchKey : search;
      console.log("🚀 ~ file: index.js:129 ~ getShortCode ~ Search:", Search);
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      setSearchKey(Search);
      setCurrentPage(Page);
      setPageLimit(Limit);
      let obj = {
        page: Page,
        limit: Limit,
        search: Search,
      };
      const { data, responseCode } = await UserService.getAllUsers(obj);
      console.log("🚀 ~ file: index.js:68 ~ getUsers ~ data:", data);
      const filteredUsers = data?.users?.filter(
        (users) => users.role.name !== "Admin"
      );
      if (responseCode === 200) {
        setRow(filteredUsers);
        console.log(filteredUsers);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
          {" "}
          Users List
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: 2,
          maxHeight: { xs: "auto", sm: "auto", md: "calc(100vh - 300px)" },
        }}
      >
        <Table sx={{ minWidth: "650px" }}>
          <TableHead>
            <TableRow sx={{ bgcolor: Colors.lightGray }}>
              {tableHead.map((cell, index) => (
                <Cell key={index}>{cell}</Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader ? (
              <Fragment>
                {row?.length > 0 ? (
                  <Fragment>
                    {row?.map((row, index) => (
                      <Row
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Cell>
                          <CardMedia
                            component={"img"}
                            src={
                              row?.picture === "/media/default_avatar.png"
                                ? Images.defaultAvatar
                                : baseUrl + row?.picture
                            }
                            sx={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </Cell>
                        <Cell>{row?.name}</Cell>
                        <Cell>{row?.phone}</Cell>
                        <Cell>{row?.email}</Cell>
                        <Cell>
                          {moment(row?.birth_date).format("DD/MM/yyyy")}
                        </Cell>
                        <Cell>{row?.country_id?.name}</Cell>
                        <Cell>
                          {moment(row?.createdAt).format("DD/MM/yyyy")}
                        </Cell>
                      </Row>
                    ))}
                  </Fragment>
                ) : (
                  <Row>
                    <Cell colSpan={7} align="center" sx={{ fontWeight: 600 }}>
                      c
                    </Cell>
                  </Row>
                )}
              </Fragment>
            ) : (
              <Row>
                <Cell colSpan={7} align="center" sx={{ fontWeight: 600 }}>
                  <CircleLoading />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          bgcolor: [Colors.white],
          p: 2,
          borderRadius: "12px",
          my: 3,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / pageLimit)}
          showFirstButton
          showLastButton
          onChange={(e, page) => getUsers("", page, "")}
        />
      </Box>
    </Box>
  );
}

export default UserList;
